<template>
  <product-page :title="title" :subtitle="subtitle">
    <contact-popup topic="Systemy multimedialne" class="mb-6" />
    <mms-features />
  </product-page>
</template>

<script>
import MmsFeatures from "@/components/products/mms/MmsFeatures";
import ProductPage from "@/components/products/ProductPage";
import ContactPopup from "@/components/ContactPopup";

export default {
  components: {ContactPopup, ProductPage, MmsFeatures},
  data() {
    //TODO: missing translations or restful request
    return {
      title: "Multimedialny system Siegla MMS",
      subtitle: "Wprowadziliśmy na rynek nowoczesny system – Digital Signage / System zarządzania treścią – umożliwiający łatwe i wygodne zarządzanie treścią, pozwalając na aktualizację i rozbudowę serwowanej treści. System opracowany przez naszych specjalistów jest elastyczny, umożliwia dopasowanie oprogramowania idealnie do potrzeb Klienta, który korzysta z naszych produktów w postaci kiosków multimedialnych. System może być uruchamiany na komputerze osobistym, serwerze w sieci lokalnej oraz zdalnie przy pomocy globalnych dostawców Digital signage, takich jak Platforma Biznesu. "
    }
  },
}
</script>
