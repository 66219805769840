<template>
  <v-sheet color="#fbfbfb">
    <v-divider/>
    <v-sheet class="ma-auto">
      <v-container>
        <v-row>
          <v-col class="my-8">
            <TextHeading :level=2>{{title}}</TextHeading>
            <div class="grey--text mt-2">{{subtitle}}</div>
          </v-col>
        </v-row>
      </v-container>
      <multi-features-box :features="qmsComponents" ></multi-features-box>
    </v-sheet>
  </v-sheet>
</template>

<script>
import TextHeading from "@/modules/TextHeading";
import MultiFeaturesBox from "@/components/products/common/MultiFeaturesBox";
export default {
  components: {MultiFeaturesBox, TextHeading},
  data() {

    //TODO: missing translations or restful request
    return {

      title: 'Kluczowe funkcjonalności systemu Siegla MMS',
      subtitle: '',

      qmsComponents: [
        {
          name: "Odtwarzanie lokalnych zasobów multimedialnych",
          short_description: "",
          description: "Siegla MMS oferuje zaawansowane repozytorium danych multimedialnych, zapewniające kontrolę nad plikami oraz ich dystrybucję do urządzeń docelowych. Taka konfiguracja pozwala na kompleksowe zarządzanie biblioteką multimediów w sposób scentralizowany."
        },
        {
          name: "Strumieniowanie zasobów zdalnych",
          short_description: "",
          description: "System umożliwia przesyłanie plików wideo z popularnych, zewnętrznych serwisów takich jak YouTube. Taka konfiguracja pozwala na emisję transmisji na żywo oraz zoptymalizowanie zużycia przestrzeni dyskowej w urządzeniach docelowych."
        },
        {
          name: "Dowolny podział ekranu",
          short_description: "",
          description: "Prezentowane treści mogą zostać dowolnie rozmieszone w przygotowanych obszarach roboczych. Definicja parametrów związanych ze sposobem i proporcjami podziału możliwa jest za pośrednictwem dedykowanego panelu administracyjnego."
        },
        {
          name: "Integracja z systemem kolejkowym",
          short_description: "",
          description: "Stale rozszerzane reporyzorium autorskich aplikacji multimedialnych jest w pełni kompatybilne z autorskim systemiem Siegla QMS, dzięki czemu możliwe jest jednoczesne prezentowanie treści multimedialnych oraz skorzystanie z ekranu, jako komponentu informującego o stanie kolejki. "
        },
        {
          name: "Harmonogramowanie trybu działania",
          short_description: "",
          description: "Harmonogram pozwala na zaplanowanie wyświetlania materiałów oraz trybu działania urządzeń docelowych we wskazanych ramach czasowych. Zarówno rodzaj treści, jak i schemat podziału ekranu mogą zostać poddane harmonogramom jednorazowym oraz cyklicznym."
        },
        {
          name: "Kompatybilność z aplikacjami firm trzecich",
          short_description: "",
          description: "Unikalną cechą systemu Siegla MMS jest możliwość integracji z dowolną aplikacją posiadającą interfejs graficzny, oraz kompatybilną ze standardem ELF2."
        },
      ]
    }
  },
}
</script>

<style scoped>

.item-hovered {
  cursor: pointer !important;
}

</style>
